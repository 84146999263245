import { useEffect } from 'react';

import { useAppDispatch } from 'hooks';
import { LoginMethod } from 'models';
import { authActions } from 'store';
import SocialLoginRegisterForm from './SocialLoginRegisterForm';

const SocialLoginRegister = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authActions.setLoginMethod(LoginMethod.SocialLogin));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SocialLoginRegisterForm />;
};

export default SocialLoginRegister;
