import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Callout, DirectionalHint, IconButton, Stack, TextButton, TextSecondary } from '@teamviewer/ui-library';

import { LinkWithRedirect } from 'components';

import { useStyles } from './PasswordInfo.styles';

const PasswordInfo = ({ idp }: { idp: string }) => {
  const { passwordInfoContainer, calloutStyles, infoIconStyles, infoLinkStyles } = useStyles();
  const { t } = useTranslation(['socialloginregister']);
  const [calloutVisible, setCalloutVisible] = useState(false);
  const toggleCallout = () => setCalloutVisible((visible) => !visible);
  const iconId = 'password-info-icon';
  return (
    <>
      <Stack className={passwordInfoContainer} horizontal>
        <TextButton onClick={toggleCallout} data-testid="registration-third-party-why-is-needed">
          {t('passwordInfo')}
        </TextButton>
        <IconButton
          id={iconId}
          data-testid={iconId}
          onClick={toggleCallout}
          className={infoIconStyles}
          iconProps={{ iconName: 'Info16RegularIcon' }}
        />
      </Stack>
      {calloutVisible && (
        <Callout
          data-testid="passwordinfo-callout"
          target={`#${iconId}`}
          className={calloutStyles}
          directionalHint={DirectionalHint.rightCenter}
          gapSpace={36}
        >
          <Stack>
            <TextSecondary>{t('passwordInfoBubble', { idp })}</TextSecondary>
            <LinkWithRedirect href="https://www.teamviewer.com/link/?url=911893" target="_blank">
              <Stack className={infoLinkStyles} horizontal>
                <TextSecondary>{t('passwordInfoLearnMore')}</TextSecondary>
                <IconButton iconProps={{ iconName: 'OpenIcon' }} className={infoIconStyles} />
              </Stack>
            </LinkWithRedirect>
          </Stack>
        </Callout>
      )}
    </>
  );
};

export default PasswordInfo;
