import { lazy, useEffect } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, useRoutes, useSearchParams } from 'react-router-dom';

import { useConfigManager } from 'config/useConfigManager';
import { useNavigateWithRedirect } from 'hooks';
import { useAnalyticsPageView } from 'modules/Analytics';
import { validateIsCustomProtocol } from 'utils/validationFunctions';

const RegisterAccount = lazy(() => import('pages/RegisterAccount' /* webpackChunkName: "register_account" */));
const Login = lazy(() => import('pages/Login' /* webpackChunkName: "login" */));
const TwoFactor = lazy(() => import('pages/TwoFactor' /* webpackChunkName: "2fa" */));
const EnforceTFA = lazy(() => import('pages/TFAActivation' /* webpackChunkName: "enforce_tfa" */));
const VerifyAccountContainer = lazy(
  () => import('pages/VerifyAccount/VerifyAccountContainer') /* webpackChunkName: "verify_account" */,
);
const VerifyAccountPendingContainer = lazy(
  () => import('pages/VerifyAccount/VerifyAccountPendingContainer' /* webpackChunkName: "verify_account_pending" */),
);
const ForgotPassword = lazy(() => import('pages/ForgotPassword' /* webpackChunkName: "forgot_password" */));
const ForgotEncryptionKey = lazy(() => import('pages/ForgotEncryptionKey' /* webpackChunkName: "forgot_password" */));
const ResetPassword = lazy(() => import('pages/ResetPassword' /* webpackChunkName: "reset_password" */));
const UntrustedDevice = lazy(() => import('pages/UntrustedDevice' /* webpackChunkName: "untrusted_device" */));
const TrustDeviceContainer = lazy(
  () => import('./pages/TrustDevice/TrustDeviceContainer' /* webpackChunkName: "trust_device" */),
);
const DeactivateTwoFactor = lazy(() => import('pages/DeactivateTwoFactor'));
const TwoFactorDeactivated = lazy(() => import('pages/TwoFactorDeactivated'));
const SSOLink = lazy(() => import('pages/SSOLink'));
const SocialLoginSignIn = lazy(() => import('pages/SocialLoginSignIn'));
const SocialLoginRegister = lazy(() => import('pages/SocialLoginRegister'));
const ReturnUrlSSO = lazy(() => import('pages/ReturnUrlSSO'));
const LaunchTeamViewer = lazy(() => import('pages/LaunchTeamViewer' /* webpackChunkName: "launch_teamViewer" */));
const NotAccepted = lazy(() => import('pages/NotAccepted' /* webpackChunkName: "not_accepted" */));
const NotFound = lazy(() => import('pages/NotFound'));
const OAuthError = lazy(() => import('pages/OAuthError'));
const OAuthGrantAccess = lazy(() => import('pages/OAuthGrantAccess'));

type Route = RouteObject & {
  hide?: boolean;
  children?: Route[];
};

const routes = (featureFlags: AppConfig['featureFlags']): Route[] => [
  {
    index: true,
    element: <Login />,
  },
  {
    path: 'register',
    element: <RegisterAccount />,
    hide: !featureFlags.createAccount,
  },
  {
    path: 'tfa',
    element: <TwoFactor />,
  },
  {
    path: 'enforce-tfa',
    element: <EnforceTFA />,
  },
  {
    path: 'sso/tensor',
    element: <h2>SSO Tensor</h2>,
    hide: !featureFlags.tensorSso,
  },
  {
    path: 'sso/returnurl',
    element: <ReturnUrlSSO />,
    hide: !featureFlags.tensorSso,
  },
  {
    path: 'sso/sso-link',
    element: <SSOLink />,
    hide: !featureFlags.tensorSso,
  },
  {
    path: 'sociallogin/signin',
    element: <SocialLoginSignIn />,
    hide: !featureFlags.socialLogin,
  },
  {
    path: 'sociallogin/register',
    element: <SocialLoginRegister />,
    hide: !featureFlags.socialLogin,
  },
  {
    path: 'link/activateaccount',
    element: <VerifyAccountContainer />,
    hide: !featureFlags.verifyDevice,
  },
  {
    path: 'verifyaccount',
    element: <VerifyAccountPendingContainer />,
    hide: !featureFlags.createAccount,
  },
  {
    path: 'untrustedDevice',
    element: <UntrustedDevice />,
  },
  {
    path: 'link/authorizedevice',
    element: <TrustDeviceContainer />,
    hide: !featureFlags.verifyDevice,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
    hide: !featureFlags.forgotPassword,
  },
  {
    path: 'link/resetpassword',
    element: <ResetPassword />,
    hide: !featureFlags.forgotPassword,
  },
  // TODO(TEAM-64777): remove this entry after deleting the email template for resetting encryption key
  {
    path: 'link/resetencryptionkey',
    element: <ResetPassword />,
    hide: !featureFlags.forgotPassword,
  },
  {
    path: 'forgotencryptionkey',
    element: <ForgotEncryptionKey />,
    hide: !featureFlags.forgotPassword,
  },
  {
    path: 'launch-teamviewer',
    element: <LaunchTeamViewer />,
  },
  {
    path: 'deactivate-tfa',
    element: <DeactivateTwoFactor />,
    hide: !featureFlags.twoFactorDeactivation,
  },
  {
    path: 'tfa-deactivated',
    element: <TwoFactorDeactivated />,
    hide: !featureFlags.twoFactorDeactivation,
  },
  {
    path: 'not-accepted',
    element: <NotAccepted />,
  },
  {
    path: 'not-found',
    element: <NotFound />,
  },
  {
    path: 'oauth2/grantaccesscard',
    element: <OAuthGrantAccess />,
    hide: !featureFlags.requestPermission,
  },
  {
    path: 'oauth2/error',
    element: <OAuthError />,
    hide: !featureFlags.requestPermission,
  },
  {
    path: '*',
    element: <Navigate to="/not-found" replace />,
  },
];

function filterRoutes(routes: Route[]) {
  const filteredRoutes: Route[] = [];
  for (const route of routes) {
    if (!route.hide) {
      if (route.children) {
        route.children = filterRoutes(route.children);
      }
      filteredRoutes.push(route);
    }
  }
  return filteredRoutes;
}

export default function Router() {
  const navigate = useNavigateWithRedirect();
  const [searchParams] = useSearchParams();
  const configManager = useConfigManager();
  const featureFlags = configManager.get('featureFlags');
  useAnalyticsPageView();

  useEffect(() => {
    if (!featureFlags.webClientRedirect && !validateIsCustomProtocol(searchParams.get('redirect_uri') || '')) {
      navigate('/not-found', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags]);

  return useRoutes(filterRoutes(routes(featureFlags)));
}
