import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { spacing, Stack, TextPrimary, TextSecondary, TextWithTooltip, themeSpacings } from '@teamviewer/ui-library';

import { Banner, IconLink, InfoContainer, Modal, ModalBanner, ModalMain, SubmitButton } from 'components';
import { useConfigManager } from 'config/useConfigManager';
import {
  useAppDispatch,
  useAppSelector,
  useConfirmationToast,
  useErrorMessage,
  useFormWithCaptcha,
  useNavigateWithRedirect,
} from 'hooks';
import { ResetPasswordStatus, ResetPasswordType } from 'models';
import { resetPasswordActions, sendForgotPasswordEmailAction } from 'store';
import { useLinkStyles } from 'utils/commonStyles';
import { splitAndStyleString } from 'utils/stringUtils';

import { useStyles } from './ForgotEncryptionKeyForm.styles';

const ForgotEncryptionKeyForm = () => {
  const { t } = useTranslation(['forgotPassword', 'common', 'validation', 'login', 'banner']);
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithRedirect();
  const { status } = useAppSelector((state) => state.resetPasswod);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') ?? '';
  const configManager = useConfigManager();
  const redirectUri = searchParams.get('redirect_uri') ?? configManager.get('defaultRedirectUri');
  const idProvider = searchParams.get('id_provider');
  const idToken = searchParams.get('id_token');
  const isLoading = status === ResetPasswordStatus.InProgress;
  const { errorMessage, errorCode } = useErrorMessage((state) => state.resetPasswod.error);
  const { linkStyles } = useLinkStyles();

  const { optionalTextStyles, subtitleStyles, titleStyles, tooltipHost } = useStyles();

  useConfirmationToast({
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
      errorCode,
    },
    showError: errorMessage !== '',
  });

  useEffect(() => {
    if (!email) {
      navigate('/not-found');
      return;
    }

    dispatch(resetPasswordActions.setEmail(email));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const { captchaComponent, recaptchaLoaded, isReCaptchaSolved, executeRecaptcha } = useFormWithCaptcha<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    isVisible: true,
    defaultValues: { email },
    onSubmit(data) {
      dispatch(
        sendForgotPasswordEmailAction({
          passwordType: ResetPasswordType.EncryptionKey,
          email,
          redirectUri,
          'captcha-response': data.captchaResponse,
        }),
      );
    },
  });

  return (
    <Modal>
      <ModalMain>
        <Stack>
          <Stack.Item>
            <Stack as="header">
              <IconLink iconName="Back" as="LinkWithRedirect" link="/" tabIndex={1} data-testid="signin-go-back-link">
                <TextPrimary
                  as="h1"
                  nowrap
                  block
                  className={titleStyles}
                  data-testid="forgot-encryption-key-reset-title"
                >
                  {t('title')}
                </TextPrimary>
              </IconLink>

              <TextSecondary
                variant="medium"
                className={subtitleStyles}
                nowrap
                block
                data-testid="forgot-encryption-key-reset-subtitle"
              >
                {t('subtitle')}
              </TextSecondary>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <form onSubmit={executeRecaptcha} noValidate data-testid="forgot-encryption-key-reset-email-form">
              <Stack tokens={{ childrenGap: themeSpacings.l }}>
                <Stack.Item>
                  <InfoContainer
                    data-testid="forogt-encryption-key-email-info-container"
                    icon="./assets/default_account_profile_picture.svg"
                    iconAccessibility={t('common:profilePictureAccessibility')}
                    text={<TextWithTooltip hostClassName={tooltipHost} text={email} />}
                  />
                </Stack.Item>
                <Stack.Item>{captchaComponent}</Stack.Item>
                <Stack tokens={{ childrenGap: spacing(8) }}>
                  <Stack.Item>
                    <SubmitButton
                      label={t('send')}
                      tabIndex={2}
                      checkedLabel={t('sent')}
                      isValid={isReCaptchaSolved}
                      isLoading={isLoading || !recaptchaLoaded}
                      isCheckmarkVisible={false}
                      data-testid="forgot-encryption-key-reset-submit-btn"
                    />
                  </Stack.Item>
                </Stack>
              </Stack>
            </form>
          </Stack.Item>
        </Stack>
        <Stack>
          <Stack.Item className={optionalTextStyles}>
            {splitAndStyleString(
              t('rememberedThePassword', {
                link: `<>${t('signIn')}</>`,
              }),
              /<>|<\/>/,
              linkStyles,
              `/sociallogin/signin/?email=${email}&id_provider=${idProvider}&id_token=${idToken}`,
              2,
              '_self',
              'forgot-encryption-key-verify-remembered',
            )}
          </Stack.Item>
        </Stack>
      </ModalMain>
      <ModalBanner>
        <Banner.Security />
      </ModalBanner>
    </Modal>
  );
};

export default ForgotEncryptionKeyForm;
