import { makeStyles } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      passwordInfoContainer: {
        'button:hover': {
          color: tokens.colors.theme.themePrimary,
          backgroundColor: 'inherit',
        },
        'button:active': {
          color: tokens.colors.theme.themeDarker,
          backgroundColor: 'inherit',
        },
      },
      calloutStyles: {
        maxWidth: 373,
        padding: tokens.spacings.sm,
        borderRadius: tokens.spacing(3),
        'div.ms-Callout-beakCurtain': {
          borderRadius: tokens.spacing(3),
        },
      },
      infoIconStyles: {
        height: tokens.spacings.l,
        color: tokens.colors.theme.themePrimary,
        'span.ms-Button-flexContainer': {
          width: tokens.spacings.l,
        },
      },
      infoLinkStyles: {
        alignItems: 'center',
        span: {
          color: tokens.colors.theme.themePrimary,
        },
        'button:hover': {
          color: tokens.colors.theme.themePrimary,
          backgroundColor: 'inherit',
        },
        'button:active': {
          color: tokens.colors.theme.themeDarker,
          backgroundColor: 'inherit',
        },
      },
    }) as const,
);
