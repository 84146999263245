import { ReactComponent as AppFolder } from '@fluentui/svg-icons/icons/app_folder_20_regular.svg';
import { ReactComponent as CheckmarkIcon } from '@fluentui/svg-icons/icons/checkmark_12_regular.svg';
import { ReactComponent as CloudOfflineIcon } from '@fluentui/svg-icons/icons/cloud_off_20_regular.svg';
import { ReactComponent as Dismiss20Icon } from '@fluentui/svg-icons/icons/dismiss_20_regular.svg';
import { ReactComponent as EyeHideIcon } from '@fluentui/svg-icons/icons/eye_off_16_regular.svg';
import { ReactComponent as EyeShowIcon } from '@fluentui/svg-icons/icons/eye_show_16_regular.svg';
import { ReactComponent as Info16FilledIcon } from '@fluentui/svg-icons/icons/info_16_filled.svg';
import { ReactComponent as Info16RegularIcon } from '@fluentui/svg-icons/icons/info_16_regular.svg';
import { ReactComponent as OpenIcon } from '@fluentui/svg-icons/icons/open_16_filled.svg';
import { ReactComponent as Printer } from '@fluentui/svg-icons/icons/print_20_regular.svg';
import { ReactComponent as SendIcon } from '@fluentui/svg-icons/icons/send_16_regular.svg';
import { ReactComponent as WarningIcon } from '@fluentui/svg-icons/icons/warning_20_regular.svg';
import { registerIcons } from '@teamviewer/ui-library';

import { ReactComponent as AppleIcon } from 'assets/apple_icon.svg';
import { ReactComponent as BackArrowIcon } from 'assets/back_arrow.svg';
import { ReactComponent as BackArrowBlueIcon } from 'assets/back_arrow_blue.svg';
import { ReactComponent as ErrorIcon } from 'assets/error.svg';
import { ReactComponent as GlobeIcon } from 'assets/globe.svg';
import { ReactComponent as GoogleIcon } from 'assets/google_icon.svg';
import { ReactComponent as MicrosoftIcon } from 'assets/microsoft_icon.svg';

const icons = {
  Back: <BackArrowIcon />,
  BackBlue: <BackArrowBlueIcon />,
  ErrorBadge: <ErrorIcon />,
  EyeHideIcon: <EyeHideIcon />,
  EyeShowIcon: <EyeShowIcon />,
  Info16FilledIcon: <Info16FilledIcon />,
  Info16RegularIcon: <Info16RegularIcon />,
  CheckmarkIcon: <CheckmarkIcon />,
  WarningIcon: <WarningIcon />,
  SendIcon: <SendIcon />,
  Dismiss20Icon: <Dismiss20Icon />,
  GoogleIcon: <GoogleIcon />,
  MicrosoftIcon: <MicrosoftIcon />,
  AppleIcon: <AppleIcon />,
  CloudOfflineIcon: <CloudOfflineIcon />,
  Globe: <GlobeIcon />,
  AppFolder: <AppFolder />,
  Printer: <Printer />,
  OpenIcon: <OpenIcon />,
} as const;

const replacementIcons = {
  Cancel: '\uE030',
  Checkmark: '\uE005',
  ChevronDown: '\uE001',
  ChevronDownSmall: '\uE051',
  ChevronRight: '\uE002',
  ChevronUpSmall: '\uE050',
  Clear: '\uE031',
  Contact: '\uE025',
  Copy: '\uE035',
  Download: '\uE036',
  Edit: '\uE032',
  Info: '\uE00B',
  InfoSolid: '\uE00A',
  More: '\uE040',
  Send: '\uE033',
  SortDown: '\uE011',
  SortUp: '\uE010',
} as const;

export type IconName = keyof typeof icons;
export type ReplacementIconName = keyof typeof replacementIcons;

// As to not break have to change the ui-libraries code, but still get the type safety
export const getIcon = (name: IconName | ReplacementIconName) => name;

export const registerCustomIcons = () => {
  registerIcons({
    fontFace: {
      fontFamily: `"Fluent UI Replacement Icons"`,
    },
    icons: replacementIcons,
  });

  registerIcons({ icons });
};
