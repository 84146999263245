import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { PasswordFormInput, spacing, Stack, TextButton, TextPrimary, themeSpacings } from '@teamviewer/ui-library';

import {
  Banner,
  IconLink,
  InfoContainer,
  LinkWithRedirect,
  Modal,
  ModalBanner,
  ModalMain,
  SubmitButton,
} from 'components';
import { useConfigManager } from 'config/useConfigManager';
import {
  useAppDispatch,
  useAppSelector,
  useAuthRedirect,
  useConfirmationToast,
  useErrorMessage,
  useFormWithCaptcha,
  useTracking,
} from 'hooks';
import { useStayTunedDialog } from 'hooks/use-stay-tuned-dialog';
import { LoginMethod, LoginStatus } from 'models';
import { useAnalytics } from 'modules/Analytics';
import { authActions, getSsoVerificationToken, loginAction } from 'store';
import { useLinkStyles } from 'utils/commonStyles';
import { getPasswordValidationRules } from 'utils/validationRules';

import { useStyles } from 'pages/SocialLoginSignIn/SocialLoginSignIn.styles';

const SocialLoginSignIn = () => {
  const { t } = useTranslation(['sociallogin', 'common', 'banner', 'validation', 'toastMessage']);
  const { trackAction, trackView } = useTracking();
  const dispatch = useAppDispatch();
  const configManager = useConfigManager();
  const featureFlags = configManager.get('featureFlags');
  const {
    isLoginInProgress: isLoading,
    loginStatus,
    error,
    ssoInfo,
    loginMethod,
  } = useAppSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const { render: renderStayTunedDialog, toggle: toggleStayTunedDialog } = useStayTunedDialog();
  const email = searchParams.get('email') || '';
  const idProvider = searchParams.get('id_provider') || '';
  const idToken = searchParams.get('id_token');
  const redirectUri = searchParams.get('redirect_uri') ?? configManager.get('defaultRedirectUri');
  const { errorMessage, setErrorMessage, errorCode } = useErrorMessage((state) => state.auth.error, {
    defaultMessageCreator: () => t('defaultLoginError'),
  });
  const { analyticsAction } = useAnalytics();

  const { emailTextStyles, formSectionStyles, titleStyles, textWrapStyles } = useStyles();
  const { linkStyles } = useLinkStyles();

  useEffect(() => {
    if (loginStatus === LoginStatus.ReadyToRedirect) {
      if (idProvider.toLowerCase() === 'google') {
        analyticsAction({ action: 'signIn', method: 'google' });
      }
      if (idProvider.toLowerCase() === 'microsoft') {
        analyticsAction({ action: 'signIn', method: 'microsoft' });
      }
      if (idProvider.toLowerCase() === 'apple') {
        analyticsAction({ action: 'signIn', method: 'apple' });
      }
      if (idProvider.toLowerCase() === 'tensor') {
        analyticsAction({ action: 'signIn', method: 'tensor' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  useEffect(() => {
    trackView('social-login-account-sign-in-dialog', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loginMethod === LoginMethod.SocialLogin) {
      return;
    }

    dispatch(authActions.setLoginMethod(LoginMethod.SocialLogin));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useConfirmationToast({
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
      errorCode,
    },
    resetAction: () => setErrorMessage(''),
    showError: errorMessage !== '',
  });

  useAuthRedirect();

  useEffect(() => {
    if (error.isError && !errorMessage) {
      setErrorMessage(t('defaultLoginError'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    // If SsoVerificationToken has been obtained before, we don't need to ask for it again.
    if (ssoInfo.ssoVerificationToken || !email) {
      return;
    }

    dispatch(getSsoVerificationToken(email));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const {
    control,
    formState: { isValid },
    recaptchaLoaded,
    captchaComponent,
    executeRecaptcha,
  } = useFormWithCaptcha<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    onSubmit({ encryptionKey, captchaResponse }) {
      trackAction('user-account', 'social-login-account-sign-in');

      if (email === null || idToken === null) {
        dispatch(authActions.setError({ isError: true, messageCreator: () => t('missingData') }));
        return;
      }

      dispatch(
        loginAction({
          username: email,
          password: encryptionKey,
          redirectUri,
          socialLoginIdentityToken: idToken,
          ssoVerificationToken: ssoInfo.ssoVerificationToken,
          'invisiblecaptcha-response': captchaResponse,
        }),
      );
    },
  });

  return (
    <Modal>
      <ModalMain>
        {renderStayTunedDialog()}
        <Stack>
          <IconLink
            iconName="Back"
            link="/"
            tabIndex={1}
            data-testid="signin-sso-google-go-back-link"
            onClick={() => {
              trackAction('user-account', 'social-login-account-sign-in-cancel');
            }}
          >
            <TextPrimary as="h1" block data-testid="signin-sso-google-title" className={titleStyles}>
              {t('enterpassword')}
            </TextPrimary>
          </IconLink>
          <Stack>
            <InfoContainer
              icon={`${process.env.PUBLIC_URL}/assets/default_account_profile_picture.svg`}
              iconAccessibility={t('common:profilePictureAccessibility')}
              text={
                <TextPrimary variant="medium" data-testid="signin-sso-google-email-text" className={emailTextStyles}>
                  {email}
                </TextPrimary>
              }
              data-testid="signin-sso-google-email-container"
              textWrapStyles={textWrapStyles}
            />
          </Stack>
        </Stack>
        {captchaComponent}
        <Stack
          as="form"
          data-testid="signin-sso-google-key-form"
          className={formSectionStyles}
          tokens={{ childrenGap: themeSpacings.l }}
          onSubmit={executeRecaptcha}
        >
          <PasswordFormInput
            id="encryption-Key-input"
            name="encryptionKey"
            control={control}
            inputMode="text"
            autoFocus
            tabIndex={2}
            rules={getPasswordValidationRules(t)}
            autoComplete="current-encryption-Key"
            label={t('password')}
            ariaLabel={t('password')}
            data-testid="signin-sso-google-key-input"
          />
          <Stack tokens={{ childrenGap: spacing(8) }}>
            <Stack.Item>
              <SubmitButton
                label={t('signIn')}
                tabIndex={4}
                isValid={isValid}
                isCheckmarkVisible={false}
                isLoading={isLoading || !recaptchaLoaded}
                checkedLabel={t('signedIn')}
                data-testid="signin-sso-google-submit-btn"
              />
            </Stack.Item>
            {featureFlags.forgotPassword ? (
              <LinkWithRedirect
                data-testid="signin-sso-google-forgot-key-link"
                className={linkStyles}
                href={`/forgotencryptionkey?email=${email}&id_provider=${idProvider}&id_token=${idToken}`}
                tabIndex={5}
                disabled={isLoading}
              >
                {t('forgotYourPassword')}
              </LinkWithRedirect>
            ) : (
              <TextButton
                data-testid="signin-sso-google-forgot-key-link"
                className={linkStyles}
                disabled={isLoading}
                onClick={() => {
                  toggleStayTunedDialog();
                }}
                tabIndex={5}
              >
                {t('forgotYourPassword')}
              </TextButton>
            )}
          </Stack>
        </Stack>
      </ModalMain>
      <ModalBanner>
        <Banner.SocialLoginRegister service={idProvider} />
      </ModalBanner>
    </Modal>
  );
};

export default SocialLoginSignIn;
